import React, {useState, useEffect, useRef} from "react";
import useWebSocket from "react-use-websocket";
import ReactHtmlParser from "react-html-parser";
import $ from 'jquery';

const CHRONOS_URL = "wss://chronos.omegasys.eu";

const didUnmount = useRef(false);

const STATIC_OPTIONS = {
    share: false,
    shouldReconnect: (closeEvent) => {
        return didUnmount.current === false; //useWebSocket will handle unmounting for you, but this is an example of a case in which you would not want it to automatically reconnect
    },
};
const READY_STATE_OPEN = 1;

export const ChronosClient = props => {

    const [currentSocketUrl, setCurrentSocketUrl] = useState('wss://echo.websocket.org');
    const [messageHistory, setMessageHistory] = useState([]);
    const [sendMessage, lastMessage, readyState, getWebSocket] = useWebSocket(
        currentSocketUrl,
        STATIC_OPTIONS
        // options
    );

    document.getWebSocket = getWebSocket;

    useEffect(() => {
        console.log(props);
        const chronosUrl = props.config.chronosUrl ? props.config.chronosUrl : CHRONOS_URL;
        const partyId = props.config.partyId;
        const sessionKey = props.config.sessionKey;
        const channelId = props.config.channelId;
        const language = props.config.language ? props.config.language : 'en';
        const isPrivate = partyId && sessionKey;

        if (!isPrivate) {
            if (!channelId) {
                console.error("Channel ID is required for public channels!");
            }
        }

        const publicChannelUrl = `${chronosUrl}/websocket/channel/${channelId}?language=${language}`;
        const privateChannelUrl = `${chronosUrl}/websocket/private?id=${partyId}&language=${language}&sessionKey=${sessionKey}`;

        const socketUrl = isPrivate ? privateChannelUrl : publicChannelUrl;

        setCurrentSocketUrl(socketUrl);
        setMessageHistory([]);
        console.log(socketUrl);
        return () => {
            // console.log('clean up');
            didUnmount.current = true;
        }
    }, [props.config.channelId]);

    const sendNewMessage = message => {
        return props.config.handleNewMessage(message);
    };

    const sendUpdatedEvent = () => {
        return props.config.handleUpdateEvent();
    };

    useEffect(() => {
        // const currentWSUrl = getWebSocket().url;
        // console.log('received a message from ', currentWSUrl);

        switch (props.config.displayMode) {
            case "PLAIN":
            case "CHAT":
            case "DEBUG":
                lastMessage && setMessageHistory(prev => {
                    return prev.concat(lastMessage.data);
                    // $(".chat-output").scrollTop($(".list-unstyled").height());
                });
                sendUpdatedEvent();
                break;
            case "BANNER":
                lastMessage && setMessageHistory(prev => {
                    if (prev.length > 4) {
                        const oldest = $('.banner-view').find('.chronos-packet-wrapper').first();
                        oldest.addClass('removed-banner-item').one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function (e) {
                            // $(this).remove();
                            prev.shift();
                        });
                    }
                    return prev.concat(lastMessage.data)
                });
                sendUpdatedEvent();
                break;
            case "RAW":
                lastMessage && sendNewMessage(lastMessage.data);
                break;
            default:
                break;
        }
    }, [props.config.displayMode, lastMessage]);

    const readyStateString = {
        0: "CONNECTING",
        1: "OPEN",
        2: "CLOSING",
        3: "CLOSED"
    }[readyState];

    const PlainView = (
        <div className="chronos-packet-outer-wrapper">
            {messageHistory.map((value, index) => {
                let data = JSON.parse(value);
                return <div key={index} className="chronos-packet-wrapper new-plain-item">
                    <div className="chronos-packet-inner-wrapper">{ReactHtmlParser(data.content)}</div>
                </div>;
            })}
        </div>
    );

    const ChatView = (
        <div className="chronos-packet-outer-wrapper chat-view">
            {messageHistory.map((value, index) => {
                let data = JSON.parse(value);
                return <div key={index} className="chronos-packet-wrapper new-chat-item">
                    <div className="chronos-packet-inner-wrapper">
                        <img alt='' src="https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2015/11/10/0/CI_Costa-Farms-Ballad-aster.jpg.rend.hgtvcom.966.644.suffix/1447169929799.jpeg"/>
                        {ReactHtmlParser(data.content)}
                    </div>
                </div>;
            })}
        </div>
    );

    const BannerView = (
        <div className="chronos-packet-outer-wrapper banner-view">
            {messageHistory.map((value, index) => {
                let data = JSON.parse(value);
                return <div key={index} className="chronos-packet-wrapper new-banner-item">
                    <div className="chronos-packet-inner-wrapper">{ReactHtmlParser(data.content)}</div>
                </div>;
            })}
        </div>
    );

    const DebugView = (
        <div>
            Channel Messages from Chronos
            <br/>
            <button onClick={() => setCurrentSocketUrl(currentSocketUrl)}>
                {currentSocketUrl}
            </button>
            <br/>
            ReadyState: {readyStateString}
            <br/>
            MessageHistory:
            {PlainView}
        </div>
    );

    const renderByDisplayMode = displayMode => {
        switch (displayMode) {
            case 'PLAIN' :
                return PlainView;
            case 'CHAT'  :
                return ChatView;
            case 'BANNER'  :
                return BannerView;
            case 'DEBUG' :
                return DebugView;
            case 'RAW' :
                return null;
            default:
                return PlainView;
        }
    };

    return (
        renderByDisplayMode(props.config.displayMode)
    );
};
